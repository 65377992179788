import { Sample } from 'api/Samples/types';
import { isDataScienceAdmin } from 'common/helpers/user';
import { SyncStatus } from 'common/types/Freezerworks';
import SampleInput from 'components/AdminDashboard/components/SamplesPage/components/SamplesListModal/components/SampleInput/SampleInput';
import { RootState } from 'store';

import React, { useState, useEffect } from 'react';
import { Button, Grid, Modal, Paper, Typography } from '@mui/material';
import { useSelector } from 'react-redux';

interface Props {
  userId: number;
  onCancel: () => void;
}

const SamplesListModal: React.FC<Props> = ({ userId, onCancel }) => {
  const { myUser } = useSelector((store: RootState) => store.user);
  const { users } = useSelector((store: RootState) => store.users);

  const [ addingSampleInProgress, setAddingSampleInProgress ] = useState<boolean>(false);
  const [ sampleList, setSampleList ] = useState<Sample[]>([]);

  useEffect(() => {
    const { samples } = users.find(user => user.id === userId)!;
    const reversedSamples = [ ...samples ].reverse();
    setSampleList(reversedSamples);
  }, [ users, userId, setSampleList ]);

  const emptySample: Sample = {
    id: 0,
    userId,
    sampleId: '',
    createdAt: '',
    pipeline: '',
    pipelineStage: '',
    freezerworksStatus: {
      id: null,
      syncStatus: SyncStatus.NOT_SYNC,
      syncErrorMessage: null,
    },
  };

  const addSample = () => setAddingSampleInProgress(true);
  const showEmptySampleInput = () => setAddingSampleInProgress(false);

  return (
    <Modal open onClose={onCancel}>
      <Paper
        sx={{
          borderRadius: '20px',
          elevation: 9,
          position: 'absolute',
          top: '50%',
          left: '50%',
          transform: 'translate(-50%, -50%)',
          outline: 'none',
          padding: 3,
          width: { xs: '100%', md: '860px' },
        }}
        data-testid="samples-list-modal"
      >
        <Grid container item spacing={3} xs={12}>
          <Grid item xs={12}>
            <Typography variant="textXLBold">
              User’s samples
            </Typography>
          </Grid>
          {(sampleList.length > 0 || addingSampleInProgress) && (
            <Grid item xs={12} display="flex" flexDirection="column-reverse" sx={{ maxHeight: '382px', overflowY: 'auto' }}>
              {addingSampleInProgress && (
                <SampleInput key={emptySample.id} sample={emptySample} removeEmptySample={showEmptySampleInput} />
              )}
              {sampleList.map(sample => <SampleInput key={sample.id} sample={sample} removeEmptySample={showEmptySampleInput} />)}
            </Grid>
          )}
          {!isDataScienceAdmin(myUser) && (
            <Grid item xs={12}>
              {!addingSampleInProgress ? (
                <Button variant="contained" onClick={addSample}>
                  Add sample
                </Button>
              ) : (
                <Typography>Please save the last added sample before adding a new one</Typography>
              )}
            </Grid>
          )}
          <Grid item xs={12}>
            <Button variant="outlined" onClick={onCancel}>
              Close
            </Button>
          </Grid>
        </Grid>
      </Paper>
    </Modal>
  );
};

export default SamplesListModal;
