import { EHRStatus, RegistrationFilter, TestKitShippedFilter, UsersFilter } from 'api/User/types';
import { ReportsFilter } from 'api/Reports/types';
import { SyncStatus } from 'common/types/Freezerworks';
import { SearchParams } from 'common/types/SearchParams';

import { useSearchParams } from 'react-router-dom';
import { useCallback } from 'react';

const usePaginationSearchParams = () => {
  const [ searchParams, setSearchParams ] = useSearchParams();

  const pageParam = Number(searchParams.get(SearchParams.PAGE)) || 1;
  const rowsParam = Number(searchParams.get(SearchParams.ROWS)) || 10;
  const usernameParam = searchParams.get(SearchParams.USERNAME) || '';

  // The following params are used in Users Filter
  const partnerParam = searchParams.get(SearchParams.PARTNER) || '';
  const genderParam = searchParams.get(SearchParams.GENDER) || '';
  const testKitShippedParam = searchParams.get(SearchParams.TEST_KIT_SHIPPED) as any as TestKitShippedFilter || TestKitShippedFilter.ANY;
  const registrationParam = searchParams.get(SearchParams.REGISTRATION) as any as RegistrationFilter || RegistrationFilter.ANY;
  const ehrStatusParam = searchParams.get(SearchParams.EHR_STATUS) as any as EHRStatus || EHRStatus.EMPTY;
  const fwStatusParam = searchParams.get(SearchParams.FW_STATUS) as any as SyncStatus || SyncStatus.EMPTY;
  const surveyParam = searchParams.get(SearchParams.SURVEY) || '';

  // The following params are used in Reports Filter
  const ancestryReportStatusParam = searchParams.get(SearchParams.ANCESTRY_REPORT_STATUS) || '';
  const prsReportStatusParam = searchParams.get(SearchParams.PRS_REPORT_STATUS) || '';
  const dnaUploadStatusParam = searchParams.get(SearchParams.DNA_UPLOADED_STATUS) || '';
  const dnaSampleStatusParam = searchParams.get(SearchParams.DNA_SAMPLE_STATUS) || '';

  const setPageParam = useCallback((page: string) => {
    searchParams.set(SearchParams.PAGE, page);
    setSearchParams(searchParams, { replace: true });
  }, [ searchParams, setSearchParams ]);

  const setRowsParam = useCallback((rows: string) => {
    searchParams.set(SearchParams.ROWS, rows);
    setSearchParams(searchParams, { replace: true });
  }, [ searchParams, setSearchParams ]);

  const setUsernameParam = useCallback((username: string) => {
    if (username !== '') {
      searchParams.set(SearchParams.USERNAME, username);
    } else {
      searchParams.delete(SearchParams.USERNAME);
    }
    setSearchParams(searchParams, { replace: true });
  }, [ searchParams, setSearchParams ]);

  const setUsersFilter = useCallback((params: UsersFilter) => {
    if (params.partners && params.partners.length) {
      searchParams.set(SearchParams.PARTNER, params.partners.join(','));
    } else {
      searchParams.delete(SearchParams.PARTNER);
    }

    if (params.gender) {
      searchParams.set(SearchParams.GENDER, params.gender);
    } else {
      searchParams.delete(SearchParams.GENDER);
    }

    if (params.testKitShipped) {
      searchParams.set(SearchParams.TEST_KIT_SHIPPED, params.testKitShipped);
    } else {
      searchParams.delete(SearchParams.TEST_KIT_SHIPPED);
    }

    if (params.registration) {
      searchParams.set(SearchParams.REGISTRATION, params.registration);
    } else {
      searchParams.delete(SearchParams.REGISTRATION);
    }

    if (params.ehrStatus) {
      searchParams.set(SearchParams.EHR_STATUS, params.ehrStatus);
    } else {
      searchParams.delete(SearchParams.EHR_STATUS);
    }

    if (params.fwStatus) {
      searchParams.set(SearchParams.FW_STATUS, params.fwStatus);
    } else {
      searchParams.delete(SearchParams.FW_STATUS);
    }

    if (params.survey && (params.survey === '1' || params.survey === '0')) {
      searchParams.set(SearchParams.SURVEY, params.survey);
    } else {
      searchParams.delete(SearchParams.SURVEY);
    }

    setSearchParams(searchParams, { replace: true });
  }, [ searchParams, setSearchParams ]);

  const setReportsFilter = useCallback((params: ReportsFilter) => {
    if (params.ancestryReportStatus) {
      searchParams.set(SearchParams.ANCESTRY_REPORT_STATUS, params.ancestryReportStatus);
    } else {
      searchParams.delete(SearchParams.ANCESTRY_REPORT_STATUS);
    }

    if (params.prsReportStatus) {
      searchParams.set(SearchParams.PRS_REPORT_STATUS, params.prsReportStatus);
    } else {
      searchParams.delete(SearchParams.PRS_REPORT_STATUS);
    }

    if (params.dnaUploadedStatus) {
      searchParams.set(SearchParams.DNA_UPLOADED_STATUS, params.dnaUploadedStatus);
    } else {
      searchParams.delete(SearchParams.DNA_UPLOADED_STATUS);
    }

    if (params.dnaSampleStatus) {
      searchParams.set(SearchParams.DNA_SAMPLE_STATUS, params.dnaSampleStatus);
    } else {
      searchParams.delete(SearchParams.DNA_SAMPLE_STATUS);
    }

    if (params.organizationIds && params.organizationIds.length) {
      searchParams.set(SearchParams.PARTNER, params.organizationIds.join(','));
    } else {
      searchParams.delete(SearchParams.PARTNER);
    }

    setSearchParams(searchParams, { replace: true });
  }, [ searchParams, setSearchParams ]);

  const clearUsersFilter = () => {
    searchParams.delete(SearchParams.PARTNER);
    searchParams.delete(SearchParams.GENDER);
    searchParams.delete(SearchParams.TEST_KIT_SHIPPED);
    searchParams.delete(SearchParams.REGISTRATION);
    searchParams.delete(SearchParams.EHR_STATUS);
    searchParams.delete(SearchParams.SURVEY);
    setSearchParams(searchParams, { replace: true });
  };

  const clearReportsFilter = () => {
    searchParams.delete(SearchParams.ANCESTRY_REPORT_STATUS);
    searchParams.delete(SearchParams.PRS_REPORT_STATUS);
    searchParams.delete(SearchParams.DNA_UPLOADED_STATUS);
    searchParams.delete(SearchParams.DNA_SAMPLE_STATUS);
    searchParams.delete(SearchParams.PARTNER);
    setSearchParams(searchParams, { replace: true });
  };

  return {
    pageParam,
    rowsParam,
    usernameParam,
    partnerParam,
    genderParam,
    testKitShippedParam,
    registrationParam,
    ehrStatusParam,
    fwStatusParam,
    surveyParam,
    ancestryReportStatusParam,
    prsReportStatusParam,
    dnaUploadStatusParam,
    dnaSampleStatusParam,
    setPageParam,
    setRowsParam,
    setUsernameParam,
    setUsersFilter,
    setReportsFilter,
    clearUsersFilter,
    clearReportsFilter,
  };
};

export default usePaginationSearchParams;
