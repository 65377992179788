import { FreezerworksStatus } from 'common/types/Freezerworks';
import { isSuperAdmin } from 'common/helpers/user';
import { getBadgeColor, getFWStatusLabel } from 'components/AdminDashboard/components/UsersPage/components/FWColumn/helpers';
import SyncFWModal from 'components/AdminDashboard/components/UsersPage/components/SyncFWModal/SyncFWModal';
import { RootState } from 'store';

import React, { MouseEvent, useCallback, useState } from 'react';
import { Badge, Box, ClickAwayListener, IconButton, Popover, Tooltip, Typography } from '@mui/material';
import { RestartAlt } from '@mui/icons-material';
import { useSelector } from 'react-redux';

interface Props {
  freezerworksStatus: FreezerworksStatus;
  userId?: number;
}

const FWColumn: React.FC<Props> = ({ freezerworksStatus, userId }) => {
  const color = getBadgeColor(freezerworksStatus.syncStatus);
  const { myUser } = useSelector((store: RootState) => store.user);
  const [ FWPopover, setFWPopover ] = useState<{ el: HTMLElement | null }>({ el: null });
  const openFWPopover = useCallback((event: React.MouseEvent<HTMLElement>) => {
    const { currentTarget } = event;
    setFWPopover({ el: currentTarget });
  }, []);

  const [ isSyncModalOpened, setIsSyncModalOpened ] = useState<boolean>(false);

  return (
    <>
      <Typography component="div" display="flex" alignItems="center" variant="textSRegular" sx={{ whiteSpace: 'nowrap' }}>
        <Badge color={color} variant="dot" sx={{ margin: '2px 8px 0 0' }} />
        {freezerworksStatus.syncErrorMessage ? (
          <Typography
            variant="textSRegular"
            component="div"
            sx={{
              textDecorationStyle: 'dashed',
              textDecorationLine: 'underline',
              cursor: 'pointer',
            }}
            onClick={(e: MouseEvent<HTMLElement>) => openFWPopover(e)}
            data-testid="label-status-hint"
          >
            {getFWStatusLabel(freezerworksStatus.syncStatus)}
          </Typography>
        ) : getFWStatusLabel(freezerworksStatus.syncStatus)}
        {isSuperAdmin(myUser) && userId && (
          <Tooltip title="Synchronize">
            <Box component="span">
              <IconButton disabled={freezerworksStatus.syncStatus === 'Synced'} color="primary" size="small" onClick={() => setIsSyncModalOpened(true)}>
                <RestartAlt />
              </IconButton>
            </Box>
          </Tooltip>
        )}
      </Typography>
      {FWPopover.el && (
        <ClickAwayListener onClickAway={() => setFWPopover({ el: null })}>
          <Popover
            open={Boolean(FWPopover.el)}
            anchorEl={FWPopover.el}
            anchorOrigin={{
              vertical: 'bottom',
              horizontal: 'left',
            }}
            PaperProps={{
              elevation: 1,
              sx: { padding: 1.5, maxWidth: '420px' },
            }}
            onClose={() => setFWPopover({ el: null })}
            data-testid="popover-ehr-status"
            disableRestoreFocus
          >
            <Typography variant="textSRegular" component="div">
              {freezerworksStatus.syncErrorMessage}
            </Typography>
          </Popover>
        </ClickAwayListener>
      )}
      {isSyncModalOpened && userId && (
        <SyncFWModal onCancel={() => setIsSyncModalOpened(false)} userId={userId} />
      )}
    </>
  );
};

export default FWColumn;
