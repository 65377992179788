import { FWStatusLabel } from 'components/AdminDashboard/components/UsersPage/components/FWColumn/types';
import { SyncStatus } from 'common/types/Freezerworks';
import { EHRStatus } from 'api/User/types';

export const getFWStatusLabel = (fwStatus: SyncStatus): FWStatusLabel => {
  switch (fwStatus) {
    case SyncStatus.SYNCED:
      return FWStatusLabel.SYNCED;

    case SyncStatus.NEED_SYNC:
      return FWStatusLabel.NEED_TO_SYNC;

    case SyncStatus.NOT_SYNC:
      return FWStatusLabel.NOT_SYNCED;

    default:
      return FWStatusLabel.NOT_SYNCED;
  }
};

export const getBadgeColor = (fwStatus: SyncStatus) => {
  switch (fwStatus) {
    case SyncStatus.SYNCED:
      return 'success';

    case SyncStatus.NEED_SYNC:
      return 'error';

    case SyncStatus.NOT_SYNC:
      return 'primary';

    default:
      return 'primary';
  }
};
