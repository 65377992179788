import { RootState } from 'store';
import { Organization } from 'api/Organizations/types';
import { RegistrationFilter, TestKitShippedFilter, EHRStatus } from 'api/User/types';
import { FEMALE, MALE, OTHER, UNSPECIFIED } from 'common/constants/genders';
import { SyncStatus } from 'common/types/Freezerworks';
import useUsersFilter from 'components/AdminDashboard/components/UsersPage/components/UsersControlPanel/components/UsersFilterPopover/hooks';
import { getEHRStatusLabel } from 'components/AdminDashboard/components/UsersPage/components/EHRColumn/helpers';
import { getFWStatusLabel } from 'components/AdminDashboard/components/UsersPage/components/FWColumn/helpers';
import useLocalizedProfile from 'components/Dashboard/components/ProfilePage/hooks/useLocalizedProfile';

import React from 'react';
import { Button, Checkbox, FormControl, Grid, InputLabel, ListItemText, MenuItem, OutlinedInput, Popover, Select, SelectChangeEvent } from '@mui/material';
import { useSelector } from 'react-redux';
import { FormikProvider } from 'formik';

interface Props {
  onClose: () => void;
  data: HTMLButtonElement | null;
}

const UsersFilterPopover: React.FC<Props> = ({ data, onClose }) => {
  const { formik, clearFormAndSearchParams } = useUsersFilter(onClose);
  const { organizations } = useSelector((store: RootState) => store.organizations);
  const { getLocalizedGender } = useLocalizedProfile();

  const onPartnersChange = (event: SelectChangeEvent<typeof formik.values.partners>) => {
    const { target: { value } } = event;
    const partners: number[] = typeof value === 'string' ? [ Number(value) ] : value!;
    formik.setFieldValue('partners', partners);
  };

  const onClearClick = () => clearFormAndSearchParams();

  return (
    <Popover
      open={Boolean(data)}
      anchorEl={data}
      anchorOrigin={{
        vertical: 'bottom',
        horizontal: 'left',
      }}
      PaperProps={{
        elevation: 1,
        sx: { padding: 4, width: 320 },
      }}
      onClose={onClose}
      disableRestoreFocus
    >
      <FormikProvider value={formik}>
        <form onSubmit={formik.handleSubmit}>
          <Grid display="flex" flexDirection="column" item container spacing={3}>

            <Grid item xs={12}>
              <FormControl fullWidth>
                <InputLabel size="small" id="partners-label">Partner</InputLabel>
                <Select
                  labelId="partners-label"
                  multiple
                  value={formik.values.partners}
                  name="partners"
                  size="small"
                  onChange={onPartnersChange}
                  input={<OutlinedInput size="small" label="Partner" />}
                  renderValue={selectedOrg => organizations
                    .filter((org: Organization) => selectedOrg.includes(org.id))
                    .map((org: Organization) => org.name)
                    .join(', ')}
                  variant="outlined"
                  MenuProps={{
                    PaperProps: {
                      sx: {
                        maxHeight: { xs: '50%', md: '70%' },
                        overflow: 'auto',
                      },
                    },
                  }}
                >
                  {organizations.map((org: Organization) => (
                    <MenuItem key={org.id} value={org.id}>
                      <Checkbox checked={formik.values.partners?.includes(org.id)} />
                      <ListItemText primary={org.name} />
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Grid>

            <Grid item xs={12}>
              <FormControl fullWidth>
                <InputLabel size="small" id="ehr-status-label">EHR Status</InputLabel>
                <Select
                  labelId="ehr-status-label"
                  value={formik.values.ehrStatus}
                  name="ehrStatus"
                  size="small"
                  variant="outlined"
                  input={<OutlinedInput size="small" label="EHR Status" />}
                  onChange={formik.handleChange}
                >
                  <MenuItem value=""><em>None</em></MenuItem>
                  <MenuItem value={EHRStatus.NOT_PULLED}>{getEHRStatusLabel(EHRStatus.NOT_PULLED)}</MenuItem>
                  <MenuItem value={EHRStatus.PULLED}>{getEHRStatusLabel(EHRStatus.PULLED)}</MenuItem>
                  <MenuItem value={EHRStatus.FAILED}>{getEHRStatusLabel(EHRStatus.FAILED)}</MenuItem>
                  <MenuItem value={EHRStatus.IN_PROGRESS}>{getEHRStatusLabel(EHRStatus.IN_PROGRESS)}</MenuItem>
                  <MenuItem value={EHRStatus.NO_EHR_DATA}>{getEHRStatusLabel(EHRStatus.NO_EHR_DATA)}</MenuItem>
                  <MenuItem value={EHRStatus.CAN_NOT_IMPORT}>{getEHRStatusLabel(EHRStatus.CAN_NOT_IMPORT)}</MenuItem>
                  <MenuItem value={EHRStatus.PATIENT_CHART_ERROR_454}>{getEHRStatusLabel(EHRStatus.PATIENT_CHART_ERROR_454)}</MenuItem>
                </Select>
              </FormControl>
            </Grid>

            <Grid item xs={12}>
              <FormControl fullWidth>
                <InputLabel size="small" id="fw-status-label">FW Status</InputLabel>
                <Select
                  labelId="fw-status-label"
                  value={formik.values.fwStatus}
                  name="fwStatus"
                  size="small"
                  variant="outlined"
                  input={<OutlinedInput size="small" label="FW Status" />}
                  onChange={formik.handleChange}
                >
                  <MenuItem value=""><em>None</em></MenuItem>
                  <MenuItem value={SyncStatus.SYNCED}>{getFWStatusLabel(SyncStatus.SYNCED)}</MenuItem>
                  <MenuItem value={SyncStatus.NOT_SYNC}>{getFWStatusLabel(SyncStatus.NOT_SYNC)}</MenuItem>
                  <MenuItem value={SyncStatus.NEED_SYNC}>{getFWStatusLabel(SyncStatus.NEED_SYNC)}</MenuItem>
                </Select>
              </FormControl>
            </Grid>

            <Grid item xs={12}>
              <FormControl fullWidth>
                <InputLabel size="small" id="kit-status-label">Kit Status</InputLabel>
                <Select
                  labelId="kit-status-label"
                  value={formik.values.testKitShipped}
                  name="testKitShipped"
                  size="small"
                  variant="outlined"
                  input={<OutlinedInput size="small" label="Kit Status" />}
                  onChange={formik.handleChange}
                >
                  <MenuItem value=""><em>None</em></MenuItem>
                  <MenuItem value={TestKitShippedFilter.SHIPPED}>Shipped</MenuItem>
                  <MenuItem value={TestKitShippedFilter.NONE}>Not shipped</MenuItem>
                </Select>
              </FormControl>
            </Grid>

            <Grid item xs={12}>
              <FormControl fullWidth>
                <InputLabel size="small" id="registration-label">Registration</InputLabel>
                <Select
                  labelId="registration-label"
                  value={formik.values.registration}
                  name="registration"
                  size="small"
                  variant="outlined"
                  input={<OutlinedInput size="small" label="Registration" />}
                  onChange={formik.handleChange}
                >
                  <MenuItem value=""><em>None</em></MenuItem>
                  <MenuItem value={RegistrationFilter.ACTIVATED}>Activated</MenuItem>
                  <MenuItem value={RegistrationFilter.REGISTERED}>Registered</MenuItem>
                </Select>
              </FormControl>
            </Grid>

            <Grid item xs={12}>
              <FormControl fullWidth>
                <InputLabel size="small" id="gender-label">Gender</InputLabel>
                <Select
                  labelId="gender-label"
                  value={formik.values.gender}
                  name="gender"
                  size="small"
                  variant="outlined"
                  input={<OutlinedInput size="small" label="Gender" />}
                  onChange={formik.handleChange}
                >
                  <MenuItem value=""><em>None</em></MenuItem>
                  <MenuItem value={MALE}>{getLocalizedGender('male')}</MenuItem>
                  <MenuItem value={FEMALE}>{getLocalizedGender('female')}</MenuItem>
                  <MenuItem value={OTHER}>{getLocalizedGender('other')}</MenuItem>
                  <MenuItem value={UNSPECIFIED}>{getLocalizedGender('unspecified')}</MenuItem>
                </Select>
              </FormControl>
            </Grid>

            <Grid item xs={12}>
              <FormControl fullWidth>
                <InputLabel size="small" id="survey-label">Survey</InputLabel>
                <Select
                  labelId="survey-label"
                  value={formik.values.survey}
                  name="survey"
                  size="small"
                  variant="outlined"
                  input={<OutlinedInput size="small" label="Survey" />}
                  onChange={formik.handleChange}
                >
                  <MenuItem value=""><em>None</em></MenuItem>
                  <MenuItem value="1">Completed</MenuItem>
                  <MenuItem value="0">Not completed</MenuItem>
                </Select>
              </FormControl>
            </Grid>

            <Grid item xs={12} marginTop={1} container flexDirection="row-reverse">
              <Button type="submit" variant="contained" size="large" sx={{ marginLeft: 2 }}>
                Apply
              </Button>
              <Button variant="outlined" size="large" onClick={onClearClick}>
                Clear
              </Button>
            </Grid>

          </Grid>
        </form>
      </FormikProvider>
    </Popover>
  );
};

export default UsersFilterPopover;
