import { UsersFilter } from 'api/User/types';
import { darkBlue, grey800 } from 'common/constants/colors';
import usePaginationSearchParams from 'common/hooks/usePaginationSearchParams';
import { isDeliveredStatus } from 'common/helpers/shipping';
import { isDataScienceAdmin } from 'common/helpers/user';
import { HeadCell } from 'common/types/MuiComponents';
import SearchUserInput from 'common/components/SearchUserInput/SearchUserInput';
import { formatDate, formatDateTime } from 'common/helpers/dateTime';
import { ADMIN_DASHBOARD_USERS_EDIT_ROUTE, ADMIN_DASHBOARD_CLINICAL_PATIENTS_EDIT_ROUTE } from 'common/constants/urls';
import LoadingContainer from 'common/components/LoadingContainer/LoadingContainer';
import DataTable from 'common/components/DataTable/DataTable';
import ChangeTestKitReceivedModal from 'common/components/ChangeTestKitReceivedModal/ChangeTestKitReceivedModal';
import { UPDATE_TEST_KIT_RECEIVED } from 'store/users/types';
import { getUsers } from 'store/users/actions';
import { RootState } from 'store';
import { UserPageProps } from 'components/AdminDashboard/components/UsersPage/types';
import UsersControlPanel from 'components/AdminDashboard/components/UsersPage/components/UsersControlPanel/UsersControlPanel';
import EHRColumn from 'components/AdminDashboard/components/UsersPage/components/EHRColumn/EHRColumn';
import FWColumn from 'components/AdminDashboard/components/UsersPage/components/FWColumn/FWColumn';
import ResetPasswordModal from 'components/AdminDashboard/components/UsersPage/components/ResetPasswordModal/ResetPasswordModal';

import React, { useCallback, useMemo, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { Box, Checkbox, Grid, IconButton, Paper, TableCell, TableRow, Tooltip, Typography } from '@mui/material';
import { Visibility } from '@mui/icons-material';
import { upperFirst } from 'lodash';

const UsersPage: React.FC<UserPageProps> = ({ isClinicalPatientPage = false }) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const {
    usernameParam,
    partnerParam,
    testKitShippedParam,
    registrationParam,
    genderParam,
    ehrStatusParam,
    fwStatusParam,
    surveyParam,
  } = usePaginationSearchParams();

  const fetchUsers = useCallback((page: number, pageSize: number, userName: string) => {
    const filters: UsersFilter = {
      userName,
      registration: registrationParam,
      testKitShipped: testKitShippedParam,
      gender: genderParam,
      partners: partnerParam !== '' ? partnerParam.split(',').map(p => Number(p)) : [],
      ehrStatus: ehrStatusParam,
      fwStatus: fwStatusParam,
      survey: surveyParam,
    };
    dispatch(getUsers(page, pageSize, filters, isClinicalPatientPage));
  }, [ dispatch, registrationParam, testKitShippedParam, genderParam, partnerParam, ehrStatusParam, fwStatusParam, surveyParam, isClinicalPatientPage ]);

  const { myUser } = useSelector((store: RootState) => store.user);
  const isUserDataScienceAdmin = isDataScienceAdmin(myUser);

  const { users, pagination, isLoading, error } = useSelector((store: RootState) => store.users);
  const [ searchValue, setSearchValue ] = useState(usernameParam);

  const [ changeTestKitReceivedUserId, setChangeTestKitReceivedUserId ] = useState<number | null>(null);
  const handleTestKitReceivedChanged = (userId: number, testKitReceived: boolean) => {
    dispatch({ type: UPDATE_TEST_KIT_RECEIVED, data: { userId, testKitReceived } });
    setChangeTestKitReceivedUserId(null);
  };

  const [ resetPasswordUserEmail, setResetPasswordUserEmail ] = useState<string | null>(null);

  const getUserColumnLabel = useMemo(() => {
    if (isUserDataScienceAdmin) {
      return '';
    }

    return isClinicalPatientPage ? 'Patient' : 'User';
  }, [ isClinicalPatientPage, isUserDataScienceAdmin ]);

  const navigateToEditPage = useCallback((id: number) => {
    const route = isClinicalPatientPage ? ADMIN_DASHBOARD_CLINICAL_PATIENTS_EDIT_ROUTE(id) : ADMIN_DASHBOARD_USERS_EDIT_ROUTE(id);
    navigate(route);
  }, [ isClinicalPatientPage, navigate ]);

  const headCells = useMemo((): HeadCell[] => [
    {
      id: 'userId',
      numeric: false,
      label: !isClinicalPatientPage ? 'User id' : 'Patient id',
    },
    {
      id: 'dateOfRegistration',
      numeric: false,
      label: 'Date of registration',
    },
    {
      id: 'user',
      numeric: false,
      label: getUserColumnLabel,
      button: <SearchUserInput
        onChange={e => setSearchValue(e.target.value)}
        hasLeftPadding={!isUserDataScienceAdmin}
        placeholder={isClinicalPatientPage ? 'Search Patient' : undefined}
      />,
    },
    {
      id: 'dob',
      numeric: false,
      label: 'DOB',
    },
    {
      id: 'gender',
      numeric: false,
      label: 'Gender',
    },
    {
      id: 'partner',
      numeric: false,
      label: 'Partner',
    },
    {
      id: 'status',
      numeric: false,
      label: 'Registration status',
    },
    {
      id: 'fw',
      numeric: false,
      label: 'FW',
    },
    {
      id: 'ehr',
      numeric: false,
      label: 'EHR',
    },
    {
      id: 'testKitReceived',
      numeric: false,
      label: 'Test Kit Received',
    },
  ], [ isUserDataScienceAdmin, isClinicalPatientPage, getUserColumnLabel ]);

  const renderRows = useCallback(() => users
    .map((row) => {
      const isDeliveredFromUser = isDeliveredStatus(row.returnShippingStatus?.status);
      const testKitReceived = isDeliveredFromUser || !!row.testKitReceived;

      return (
        <TableRow hover tabIndex={-1} key={row.id}>
          <TableCell>
            <Typography variant="textSRegular">
              {row.id}
            </Typography>
          </TableCell>
          <TableCell>
            <Typography variant="textSRegular">
              {formatDateTime(row.createdAt)}
            </Typography>
          </TableCell>
          <TableCell
            component="th"
            scope="row"
            padding="normal"
          >
            {!isUserDataScienceAdmin && (
              <>
                <Typography variant="textSRegular" component="div">
                  {`${row.firstName} ${row.lastName}`}
                  <Tooltip title="View metadata">
                    <IconButton
                      color="primary"
                      size="small"
                      onClick={() => navigateToEditPage(row.id)}
                      sx={{ marginLeft: 1 }}
                    >
                      <Visibility fontSize="inherit" />
                    </IconButton>
                  </Tooltip>
                </Typography>
                <Typography variant="textSRegular" component="div" whiteSpace="nowrap">
                  {row.username}
                </Typography>
                {row.username && (
                  <Typography
                    variant="button"
                    sx={{ color: darkBlue, cursor: 'pointer', textDecoration: 'underline', fontSize: '12px' }}
                    onClick={() => setResetPasswordUserEmail(row.username)}
                  >
                    Reset password
                  </Typography>
                )}
              </>
            )}
          </TableCell>
          <TableCell>
            <Typography variant="textSRegular">
              {formatDate(row.dob)}
            </Typography>
          </TableCell>
          <TableCell>
            <Typography variant="textSRegular">
              {upperFirst(row.gender)}
            </Typography>
          </TableCell>
          <TableCell>
            <Typography variant="textSRegular">
              {row.organizationName || row.utmParams?.source}
            </Typography>
            <Typography variant="textSRegular" component="div" sx={{ color: grey800, fontSize: '0.75rem' }}>
              {row.organizationSite?.name}
            </Typography>
          </TableCell>
          <TableCell>
            <Typography variant="textSRegular">
              {row.registrationStatus}
            </Typography>
          </TableCell>
          <TableCell>
            <FWColumn freezerworksStatus={row.freezerworksStatus} userId={row.id} />
          </TableCell>
          <TableCell>
            <EHRColumn user={row} />
          </TableCell>
          <TableCell>
            <Checkbox
              disabled={isUserDataScienceAdmin || isDeliveredFromUser}
              onChange={() => setChangeTestKitReceivedUserId(row.id)}
              checked={testKitReceived}
              value={testKitReceived}
            />
          </TableCell>
        </TableRow>
      );
    }), [ users, isUserDataScienceAdmin, navigateToEditPage ]);

  return (
    <Box data-testid="users-page">
      <UsersControlPanel isClinicalPatientPage={isClinicalPatientPage} />
      <Paper sx={{ maxWidth: '100%' }}>
        <LoadingContainer isLoading={isLoading}>
          { error && (
            <Grid item xs={12} padding={2}>
              <Typography color="error" variant="subtitle2" sx={{ whiteSpace: 'break-spaces' }}>
                {error}
              </Typography>
            </Grid>
          )}
          <DataTable
            pagination={pagination}
            headCells={headCells}
            renderRows={renderRows}
            searchValue={searchValue}
            updateDataCallback={fetchUsers}
          />
          {changeTestKitReceivedUserId && (
            <ChangeTestKitReceivedModal
              onCancel={() => setChangeTestKitReceivedUserId(null)}
              onSave={handleTestKitReceivedChanged}
              userId={changeTestKitReceivedUserId}
            />
          )}
          {resetPasswordUserEmail && (
            <ResetPasswordModal
              onCancel={() => setResetPasswordUserEmail(null)}
              email={resetPasswordUserEmail}
            />
          )}
        </LoadingContainer>
      </Paper>
    </Box>
  );
};

export default UsersPage;
