import { EHRStatus, UserView } from 'api/User/types';
import { Pagination } from 'common/types/Pagination';
import { PipelineExecutionValues, UsersState } from 'store/users/models';
import {
  CREATE_SAMPLE,
  GET_USER_PIPELINE_EXECUTION,
  REMOVE_SAMPLE,
  RESTART_EHR_FLOW,
  SET_USERS,
  SET_USERS_ERROR,
  SET_USERS_LOADING,
  SYNC_FW,
  UPDATE_TEST_KIT_RECEIVED,
  UPDATE_USER_ORGANIZATION,
  UPDATE_USER_ROLE,
} from 'store/users/types';

import { AnyAction } from 'redux';

export const initialState: UsersState = {
  isLoading: false,
  users: [],
  pagination: {
    currentPage: 0,
    firstRowOnPage: 0,
    lastRowOnPage: 0,
    pageCount: 0,
    pageSize: 0,
    rowCount: 0,
  },
  error: '',
};

export const usersReducer = (
  state: UsersState = { ...initialState },
  action: AnyAction,
) => {
  switch (action.type) {
    case SET_USERS_LOADING:
      return {
        ...state,
        isLoading: action.value as boolean,
      };

    case SET_USERS:
      return {
        ...state,
        users: action.users as UserView[],
        pagination: action.pagination as Pagination,
        error: '',
      };

    case SET_USERS_ERROR:
      return {
        ...state,
        error: action.value as string,
      };

    case GET_USER_PIPELINE_EXECUTION:
      return {
        ...state,
        users: (state.users.map((user) => {
          if (user.id !== (action.data as PipelineExecutionValues).userId) {
            return user;
          }

          return {
            ...user,
            hgPipelineError: (action.data as PipelineExecutionValues).error,
          };
        }) as UserView[]),
      };

    case UPDATE_USER_ROLE:
      return {
        ...state,
        users: (state.users.map((user) => {
          if (user.id !== action.data.userId) {
            return user;
          }

          return {
            ...user,
            accessRights: action.data.accessLevel,
          };
        }) as UserView[]),
      };

    case UPDATE_USER_ORGANIZATION:
      return {
        ...state,
        users: (state.users.map((user) => {
          if (user.id !== action.data.userId) {
            return user;
          }

          return {
            ...user,
            organizationId: action.data.organizationId || null,
            organizationName: action.data.organizationName || null,
          };
        }) as UserView[]),
      };

    case RESTART_EHR_FLOW:
      return {
        ...state,
        users: (state.users.map((user) => {
          if (user.id !== action.data.userId) {
            return user;
          }

          return {
            ...user,
            ehrStatus: EHRStatus.IN_PROGRESS,
            pipelineExecutionMessage: '',
            pipelineExecutionDiagnostics: '',
          };
        }) as UserView[]),
      };

    case SYNC_FW:
      return {
        ...state,
        users: (state.users.map((user) => {
          const userFromData = action.data as UserView;
          if (user.id !== userFromData.id) {
            return user;
          }

          return {
            ...user,
            freezerworksStatus: userFromData.freezerworksStatus,
          };
        }) as UserView[]),
      };

    case UPDATE_TEST_KIT_RECEIVED:
      return {
        ...state,
        users: (state.users.map((user) => {
          if (user.id !== action.data.userId) {
            return user;
          }

          return {
            ...user,
            testKitReceived: action.data.testKitReceived,
          };
        }) as UserView[]),
      };

    case CREATE_SAMPLE:
      return {
        ...state,
        users: (state.users.map((user) => {
          if (user.id !== action.data.userId) {
            return user;
          }

          return {
            ...user,
            samples: [ ...user.samples, action.data ],
          };
        }) as UserView[]),
      };

    case REMOVE_SAMPLE:
      return {
        ...state,
        users: (state.users.map((user) => {
          if (user.id !== action.data.userId) {
            return user;
          }

          return {
            ...user,
            samples: user.samples.filter(sample => sample.id !== action.data.id),
          };
        }) as UserView[]),
      };

    default:
      return { ...state };
  }
};
