import { useRestartHGFlow } from 'components/AdminDashboard/components/UsersPage/components/RestartEHRModal/hooks';

import React from 'react';
import { Box, Button, Grid, Modal, Paper, Typography } from '@mui/material';
import { LoadingButton } from '@mui/lab';

interface Props {
  onCancel: () => void;
  userId: number;
  isNotPulled: boolean;
}

const RestartEHRModal: React.FC<Props> = ({ onCancel, userId, isNotPulled }) => {
  const { restartFlow, isLoading } = useRestartHGFlow(onCancel);

  return (
    <Modal open>
      <Paper
        sx={{
          borderRadius: '20px',
          elevation: 9,
          position: 'absolute',
          top: '50%',
          left: '50%',
          transform: 'translate(-50%, -50%)',
          outline: 'none',
          padding: 3,
          width: { xs: '100%', md: '600px' },
        }}
        data-testid="modal-restart-ehr-flow"
      >
        <Box>
          <Grid container spacing={3}>
            <Grid item xs={12}>
              <Typography variant="textXLBold">
                {isNotPulled ? 'Start EHR flow' : 'Restart EHR flow'}
              </Typography>
            </Grid>
            <Grid item xs={12}>
              <Typography>
                {isNotPulled ?
                  'Are you sure you want to pull EHR data for this user?' :
                  'Are you sure you want to restart EHR flow for this user?'}
              </Typography>
            </Grid>
            <Grid container spacing={2} item xs={12}>
              <Grid item>
                <LoadingButton variant="contained" onClick={() => restartFlow(userId)} loading={isLoading} data-testid="button-restart">
                  Yes
                </LoadingButton>
              </Grid>
              <Grid item>
                <Button variant="outlined" onClick={onCancel} disabled={isLoading}>
                  Cancel
                </Button>
              </Grid>
            </Grid>
          </Grid>
        </Box>
      </Paper>
    </Modal>
  );
};

export default RestartEHRModal;
