export enum SyncStatus {
  EMPTY = '',
  NOT_SYNC = 'NotSync',
  NEED_SYNC = 'NeedSync',
  SYNCED = 'Synced',
}

export interface FreezerworksStatus {
  id: string | null;
  syncStatus: SyncStatus;
  syncErrorMessage: string | null;
}
