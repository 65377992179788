import { HeadCell } from 'common/types/MuiComponents';
import LoadingContainer from 'common/components/LoadingContainer/LoadingContainer';
import DataTable from 'common/components/DataTable/DataTable';
import { formatDateTime } from 'common/helpers/dateTime';
import FWColumn from 'components/AdminDashboard/components/UsersPage/components/FWColumn/FWColumn';
import { getSamples } from 'store/samples/actions';
import { RootState } from 'store';

import React, { useCallback, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Grid, TableCell, TableRow, Typography } from '@mui/material';

const Samples: React.FC = () => {
  const dispatch = useDispatch();

  const fetchSamples = useCallback((page: number, pageSize: number) => {
    dispatch(getSamples(page, pageSize));
  }, [ dispatch ]);

  const { samples, pagination, isLoading, error } = useSelector((store: RootState) => store.samples);

  const headCells = useMemo((): HeadCell[] => [
    {
      id: 'sampleId',
      numeric: false,
      label: 'Sample id',
    },
    {
      id: 'sample',
      numeric: false,
      label: 'Sample',
    },
    {
      id: 'pipeline',
      numeric: false,
      label: 'Pipeline',
    },
    {
      id: 'pipelineStage',
      numeric: false,
      label: 'Pipeline stage',
    },
    {
      id: 'userId',
      numeric: false,
      label: 'User id',
    },
    {
      id: 'fw',
      numeric: false,
      label: 'FW',
    },
    {
      id: 'createdAt',
      numeric: false,
      label: 'Created at',
    },
  ], []);

  const renderRows = useCallback(() => samples
    .map((row) => {
      return (
        <TableRow hover tabIndex={-1} key={row.id}>
          <TableCell>
            <Typography variant="textSRegular">
              {row.id}
            </Typography>
          </TableCell>
          <TableCell>
            <Typography variant="textSRegular">
              {row.sampleId}
            </Typography>
          </TableCell>
          <TableCell>
            <Typography variant="textSRegular">
              {row.pipeline}
            </Typography>
          </TableCell>
          <TableCell>
            <Typography variant="textSRegular">
              {row.pipelineStage}
            </Typography>
          </TableCell>
          <TableCell>
            <Typography variant="textSRegular">
              {row.userId > 0 ? row.userId : ''}
            </Typography>
          </TableCell>
          <TableCell>
            <FWColumn freezerworksStatus={row.freezerworksStatus} />
          </TableCell>
          <TableCell>
            <Typography variant="textSRegular">
              {formatDateTime(row.createdAt)}
            </Typography>
          </TableCell>
        </TableRow>
      );
    }), [ samples ]);

  return (
    <LoadingContainer isLoading={isLoading}>
      { error && (
        <Grid item xs={12} padding={2}>
          <Typography color="error" variant="subtitle2" sx={{ whiteSpace: 'break-spaces' }}>
            {error}
          </Typography>
        </Grid>
      )}
      <DataTable
        pagination={pagination}
        headCells={headCells}
        renderRows={renderRows}
        updateDataCallback={fetchSamples}
      />
    </LoadingContainer>
  );
};

export default Samples;
