import { createUsersBatch } from 'api/User/api';

import { useCallback, useState } from 'react';

export const useBatchUpload = (onUpload: () => void, clinicalPatient: boolean) => {
  const [ isLoading, setIsLoading ] = useState<boolean>(false);

  const batchUpload = useCallback((file: File) => {
    setIsLoading(true);
    const data = new FormData();
    data.append('patientFile', file);

    createUsersBatch(data, clinicalPatient).finally(() => {
      onUpload();
      setIsLoading(false);
    });
  }, [ onUpload, clinicalPatient ]);

  return { batchUpload, isLoading };
};
