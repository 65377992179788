import { syncFW } from 'api/User/api';
import { SYNC_FW } from 'store/users/types';

import { useState, useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';

export const useSyncFw = (finalCallback: () => void) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const [ isLoading, setIsLoading ] = useState(false);
  const [ error, setError ] = useState<string>();

  const sync = useCallback((userId: number) => {
    setIsLoading(true);
    setError('');

    syncFW(userId)
      .then(({ data }) => {
        dispatch({ type: SYNC_FW, data });
      })
      .catch(error => setError(error?.response?.data?.message || t('errors.server_error_try_again')))
      .finally(() => {
        setIsLoading(false);
        finalCallback();
      });
  }, [ t, dispatch, finalCallback ]);

  return {
    sync,
    isLoading,
    error,
  };
};
