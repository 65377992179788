import { MailingAddressKey, User, UserAccessLevel, UsersFilter, UserView } from 'api/User/types';

export const getUserProfileProgress = (user: User) => {
  const PROGRESS_FIELDS: Array<keyof User> = [
    'firstName',
    'lastName',
    'username',
    'gender',
    'dob',
    'race',
    'mailingAddress',
  ];
  const filledFieldsCount = PROGRESS_FIELDS.reduce((count: number, fieldName: string) => {
    if (user[fieldName as keyof User]) {
      return count + 1;
    }
    return count;
  }, 0);

  return Math.round((100 / PROGRESS_FIELDS.length) * filledFieldsCount);
};

export const getUserMailingAddress = ({ mailingAddress }: User) => {
  if (!mailingAddress) {
    return '';
  }
  const mailingAddressFields: MailingAddressKey[] = [ 'zip', 'state', 'city', 'street1', 'street2', 'country' ];

  const filledFields = Object.keys(mailingAddress).reduce((result: string[], key: string) => {
    if (mailingAddressFields.includes(key as MailingAddressKey) && mailingAddress[key as MailingAddressKey]) {
      result.push(mailingAddress[key as MailingAddressKey]!);
    }

    return result;
  }, []);

  return filledFields.join(', ');
};

export const isSuperAdmin = ({ accessRights }: User | UserView) => accessRights === UserAccessLevel.SUPER_ADMIN;

export const isAdmin = ({ accessRights }: User | UserView) => accessRights === UserAccessLevel.ADMIN;

export const isBioBankAdmin = ({ accessRights }: User | UserView) => accessRights === UserAccessLevel.BIO_BANK_ADMIN;

export const isDataScienceAdmin = ({ accessRights }: User | UserView) => accessRights === UserAccessLevel.DATA_SCIENCE_ADMIN;

export const getUserFilters = (filters?: UsersFilter) => ({
  username: filters?.userName,
  isNotDefaultOnly: filters?.onlyAdmins,
  status: filters?.registration,
  testKitShipped: filters?.testKitShipped,
  gender: filters?.gender,
  organizationIds: filters?.partners,
  ehrStatus: filters?.ehrStatus,
  freezerworksSyncStatus: filters?.fwStatus,
  isSurveyCompleted: filters?.survey ? Boolean(Number(filters?.survey)) : null,
});

export const getUSZipCodeRegExp = () => {
  return /^(?:[0-9]{5},*(?:-[0-9]{4},*)?)+$/;
};
