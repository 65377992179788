export enum SearchParams {
  PAGE = 'page',
  ROWS = 'rows',
  USERNAME = 'username',
  PARTNER = 'partner',
  GENDER = 'gender',
  TEST_KIT_SHIPPED = 'test-kit-shipped',
  REGISTRATION = 'registration',
  EHR_STATUS = 'ehr',
  FW_STATUS = 'fw',
  SURVEY = 'survey',
  ANCESTRY_REPORT_STATUS = 'ancestry-report-status',
  PRS_REPORT_STATUS = 'prs-report-status',
  DNA_UPLOADED_STATUS = 'dna-uploaded-status',
  DNA_SAMPLE_STATUS = 'dna-sample-status',
}
